<template>
  <footer class="footer">
    © 2023
  </footer>
</template>

<script>
export default {
  name: "FooterComp"
}
</script>

<style lang="scss" scoped>
  .footer {
    // border: 1px solid blue;
    height: 50px;
    flex-shrink: 0;

    // background: #000;
    background-image: linear-gradient(to bottom, #434343 0%, black 100%);
    color: #fff;

    display: flex;
    justify-content: center;
    align-items: center;
  }
</style>