<template>
  <header class="dc-header">
    <div class="container dc-header__container">
      <div class="row justify-content-between dc-header__row">
        <div class="col-6 dc-header__logo">
          <router-link
            class="dc-header__logo-link"
            to="/"
          >
            <img
              class="dc-header__logo-icon"
              src="../../assets/img/logo.880c9773.svg"
              alt=""
            >
            <span class="dc-header__logo-text">Digital Code</span>
          </router-link>
        </div>

        <font-awesome-icon
          v-if="!loggedIn"
          :icon="['fas', 'sign-in']"
          class="col-1"
        />

        <div class="col-6 dc-header__navbar">
          <router-link
            v-if="loggedIn"
            to="/courses"
            class="nav-link dc-header__navbar-link"
          >
            Courses
          </router-link>
          <router-link
            v-if="loggedIn"
            to="/library"
            class="nav-link dc-header__navbar-link"
          >
            Library
          </router-link>
          <router-link
            v-if="loggedIn"
            to="/login"
            class="nav-link dc-header__navbar-link"
            @click="logout"
          >
            Logout &nbsp;<font-awesome-icon
              v-if="loggedIn"
              :icon="['fas', 'sign-out']"
            />
          </router-link>
        </div>

        <div v-if="loggedIn" class="col-1 dc-header__menubar">
          <ElDropdown>
            <span class="el-dropdown-link">
              <font-awesome-icon
                class="dc-header__menubar-icon"
                :icon="['fas', 'bars']"
              />
            </span>
            <template #dropdown>
              <ElDropdownMenu>
                <ElDropdownItem>
                  <router-link
                    to="/courses"
                    class="nav-link dc-header__navbar-link"
                  >
                    Courses
                  </router-link>
                </ElDropdownItem>
                <ElDropdownItem>
                  <router-link
                    to="/library"
                    class="nav-link dc-header__navbar-link"
                  >
                    Library
                  </router-link>
                </ElDropdownItem>
                <ElDropdownItem divided>
                  <router-link
                    to="/login"
                    class="nav-link dc-header__navbar-link"
                    @click="logout"
                  >
                    Logout &nbsp;<font-awesome-icon :icon="['fas', 'sign-out']" />
                  </router-link>
                </ElDropdownItem>
              </ElDropdownMenu>
            </template>
          </ElDropdown>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import { ElDropdown, ElDropdownItem, ElDropdownMenu } from 'element-plus';
import { mapGetters, mapActions } from 'vuex';

export default {
  name: "HeaderComp",
  components: {
    ElDropdown,
    ElDropdownMenu,
    ElDropdownItem,
  },

  data() {
    return {
      dropdown: true,
    }
  },

  computed: {
    ...mapGetters({
      loggedIn: 'getLoggedIn',
    }),
  },

  methods: {
    ...mapActions({
      logout: 'logout'
    }),
  }
}
</script>

<style lang="scss" scoped>
  .router-link-active {
    color: $base-blue;
  }

  .dc-header {
    height: 70px;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;

    // background: linear-gradient(to bottom, #fff, rgb(228, 228, 228));
    // background-image: linear-gradient( 175deg,  rgba(30,39,107,1) 6.1%, rgba(188,104,142,1) 100.2% );
    // background-image: linear-gradient(to bottom, rgba(172, 203, 238, .5) 0%, #e7f0fd 100%);
    background-image: linear-gradient(to bottom, #f3e7e9 0%, #e3eeff 99%, #e3eeff 100%);
    display: flex;
    align-items: center;

    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    border-bottom: 1px solid rgba(119, 118, 118, 0.4);

    // transition: all .3s;

    &__row {
      display: flex;
      align-items: center;
      // transition: all .3s;
    }

    &__logo {
      display: flex;
      justify-content: flex-start;
    }

    &__logo-link {
      display: flex;
      justify-content: center;
      text-decoration: none;
      color: rgba(0, 0, 0, 0.7);
      white-space: nowrap;
    }

    &__logo-icon {
      width: 30px;
      margin-right: 10px;
      transition: all .3s;
    }

    &__logo-text {
      font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
      font-weight: 700;
      font-size: 32px;
      transition: all .3s;
    }

    &__navbar {
      display: none;

      @include media-breakpoint-up(sm) {
        display: flex;
        justify-content: flex-end;
      }

      .nav-link {
        margin-left: 30px;
        font-size: 16px;
        font-weight: bold;
        white-space: nowrap;
  
        &:hover {
          text-decoration: underline;
        }
      }
    }

    &__menubar {
      display: flex;
      justify-content: flex-end;
      padding-left: 0;

      @include media-breakpoint-up(sm) {
        display: none;
      }

      .el-dropdown-link {
        cursor: pointer;
        outline: none;
        transition: 0.3s;
    
        &:hover {
          color: $base-blue;
        }
      }
    }

    &__menubar-icon {
      height: 25px;
    }
  }
</style>