<template>
  <div v-for="item, indexM in modules" :key="indexM" class="card dc-card">
    <div
      class="card-header"
      :class="{ 'collapsed' : item.isShown }"
      aria-expanded="true"
      @click="showModule(indexM)"
    >
      <div class="dc-card__title">
        <h4 class="dc-card__title-h4">
          {{ item.title }}
          <font-awesome-icon v-if="item.finished" :icon="['fas', 'check']" class="dc-card__title-icon" />
        </h4>
        <p class="dc-card__title-desc">{{ item.description }}</p>
      </div>
      <font-awesome-icon :icon="['fas', 'chevron-down']" class="dc-card__header-icon" />
    </div>
    <transition name="collapsed-card">
      <div class="card-body" v-show="item.isShown">
        <div class="video-mjs__playlist">
          <div
            v-for="(video, indexV) in item.videos"
            :key="indexV"
            class="my-card"
            :class="{'active-v': currentVideo && currentVideo.index === indexV}"
            @click="onOpenVideo(indexM, indexV)"
          >
            <div class="my-card__image">
              <img :src="video.thumbnail" class="my-card__img" alt="...">
            </div>
            <h5 class="my-card__title">{{ video.title }}</h5>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script setup>
const props = defineProps({
  modules: {
    type: Array,
    required: true,
  },
  currentVideo: {
    // type: Object,
    required: true,
  }
});

const emit = defineEmits(['showEnteredModule', 'openEnteredVideo']);

function showModule(moduleIndex) {
  emit('showEnteredModule', moduleIndex);
}

function onOpenVideo(moduleIndex, videoIndex) {
  console.log(moduleIndex, videoIndex);
  emit('openEnteredVideo', moduleIndex, videoIndex)
}
</script>

<style lang="scss" scoped>
.dc-card {
  margin-bottom: 20px;
  .card-header {
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 20px 1rem;

    &.collapsed {
      .dc-card__header-icon {
        transform: rotate(180deg);
      }
    }
  }
  &__header-icon {
    margin-left: auto;
    transition: transform .3s;
  }
  &__title {
    &-h4 {
      font-size: 18px;
      line-height: 18px;
      margin: 0 0 5px 0;
      font-weight: bold;
    }
    &-icon {
      color: rgb(127, 205, 10);
    }
    &-desc {
      font-size: 14px;
      color: #989898;
      margin: 0;
    }
  }
  .my-card {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 10px;
    background: rgba(211, 211, 211, .3);
    border-radius: 5px;
    overflow: hidden;
    gap: 10px;
    transition: 0.3s;
    
    @include media-breakpoint-up(sm) {
      flex-direction: row;
      align-items: start;
      padding: 20px;
      gap: 20px;

      &:hover {
        .my-card__image {
          opacity: 0.7;
          transform: scale(1.1);
        }
      }
    }

    &:hover {
      cursor: pointer;
      .my-card__title {
        color: #66b1ff;
      }
    }

    &__image {
      width: 100%;
      height: 100%;
      
      @include media-breakpoint-up(sm) {
        position: relative;
        width: 180px;
        height: 100px;
        flex-shrink: 0;
        transition: 0.3s;
      }
    }
    &__img {
      object-fit: cover;
      width: 100%;
      height: 100%;
      border-top-right-radius: 5px;
      border-top-left-radius: 5px;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;

      @include media-breakpoint-up(sm) {
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
      }
    }
    &__title {
      transition: 0.3s;
      font-size: 14px;
      text-align: start;
      align-self: start;
      padding: 0 10px 0;

      @include media-breakpoint-up(sm) {
        padding: 0;
      }
    }

    &__description {
      margin: 0;
    }
  }
}

.collapsed-card-enter-from {
  transform: translateX(-100%);
  opacity: 0;
}
.collapsed-card-leave-to {
  transform: translateX(100%);
  opacity: 0;
}
.collapsed-card-enter-active,
.collapsed-card-leave-active {
  transition: all 0.5s;
}
.collapsed-card-enter-to,
.collapsed-card-leave-from {
  transform: translateX(0);
  opacity: 1;
}
</style>
