import { createApp } from 'vue';
import App from './App.vue';
import store from './store';
import router from './router';
import { globalComponents } from './components/global-components';
import './assets/styles';

import OneSignalVuePlugin from '@onesignal/onesignal-vue3';
// Initialize OneSignal
const idOnesignal = process.env.NODE_ENV === 'production'
                                  ? '69ea3325-4a24-407b-b968-5091a1fbc34b'
                                  : '294dd060-443e-4b0d-ba5a-37b0177a7c12'
const oneSignalConfig = {
  appId: '69ea3325-4a24-407b-b968-5091a1fbc34b',
  serviceWorkerPath: "sw.js",
  // serviceWorkerUpdaterPath: "/OneSignalSDKUpdaterWorker.js",
  // allowLocalhostAsSecureOrigin: process.env.NODE_ENV === 'development',
  allowLocalhostAsSecureOrigin: true,
  autoRegister: true,
  notifyButton: {
    enable: true,
  },
  // Other OneSignal options go here
};

import VueMixpanel from 'vue-mixpanel';
// import './registerServiceWorker';

const app = createApp(App);

globalComponents.forEach(component => {
  app.component(component.name, component);
});

if ('serviceWorker' in navigator) { //register service worker for workbox-sw
  window.addEventListener('load', () => {
    navigator.serviceWorker.register('/sw.js')
    .then(reg => {
      console.log('Service worker registered. idapp - '+idOnesignal, reg);
    }).catch(error => {
      console.log('Service worker NOT registered:', error);
    });
  });
}

// if (Notification.permission === 'default' || Notification.permission === 'denied') {
//   Notification.requestPermission().then(permission => {
//       if (permission === 'granted') {
//           console.log('Notification permission granted.');
//           // You can now show a notification
//       } else {
//           console.log('Notification permission denied.');
//       }
//   });
// }


app
  .use(store)
  .use(router)
  .use(VueMixpanel, {
    token: '6de1a5eede4c8329fa1e608bc1b7599d',
    config: {
      debug: true,
      track_pageview: true,
      ignore_dnt: true
    }
  })
  .use(OneSignalVuePlugin, oneSignalConfig)
  .mount('#app');
