export default function useHelpers() {
  function deepCopyWithJSON(object) {
    return JSON.parse(JSON.stringify(object));
  }

  function getCleanNumber(phoneString) {
    let resultPhone = '';

    for (let i = 0; i < phoneString.length; i++) {
      if (!isNaN(phoneString[i]) && phoneString[i] !== ' ') {
        resultPhone += phoneString[i];
      }
    }

    return resultPhone;
  }

  return {
    deepCopyWithJSON,
    getCleanNumber,
  }
}
