import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import {
  ElButton,
  ElDialog,
} from 'element-plus';

export const globalComponents = [
  FontAwesomeIcon,
  ElButton,
  ElDialog,
];
