export default function useTrack(mixpanel) {
  function trackCourse(typeEvent, course = null) {
    if (typeEvent === 'viewCourse') {
      mixpanel.track('Web View Course', {
        title: course.value.title
      });
    }
    if (typeEvent === 'finished') {
      mixpanel.track('Web Finished Course', {
        title: course.value.title
      });
    }
  }

  function trackModule(typeEvent, moduleTitle, courseTitle) {
    if (typeEvent === 'viewModule') {
      mixpanel.track('Web View Module', {
        title: moduleTitle,
        course: courseTitle
      });
    }
    if (typeEvent === 'finished') {
      mixpanel.track('Web Finished Module', {
        title: moduleTitle,
        course: courseTitle
      });
    }
  }
  
  function trackVideo(typeEvent, data = null, courseTitle = '', videoTitle = '') {
    if (typeEvent === 'viewVideo') {
      mixpanel.track('Web View Video', {
        module: data ? data.moduleTitle : '',
        course: courseTitle,
        title: videoTitle,
      });
    }
    if (typeEvent === 'finished') {
      mixpanel.track('Web Finished Video', {
        module: data ? data.moduleTitle : '',
        course: courseTitle,
        title: videoTitle,
      });
    }
    if (typeEvent === 'timeSpend' && data) {
      mixpanel.track('Web Total time spent', data);
    }
  }

  function trackBook(bookTitle) {
    mixpanel.track('Web View Book', {
      title: bookTitle,
    });
  }

  function trackLogin(userEmail, userPhone) {
    mixpanel.track('Web Login', {
      user: userEmail,
      phone: userPhone,
    });
  }

  return {
    trackCourse,
    trackModule,
    trackVideo,
    trackBook,
    trackLogin,
  }
}
