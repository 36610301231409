<template>
  <div class="container container-login">
    <ElCard class="form-container">
      <h3 class="form-container__title">
        Authorization form
      </h3>
      <hr>
      <ElForm
        ref="formRef"
        :model="formData"
        label-width="80px"
        :rules="formRules"
        status-icon
        class="login-form"
        @submit.prevent="onLogin($refs.formRef)"
      >
        <ElFormItem
          label="Name"
          prop="name"
        >
          <ElInput
            v-model="formData.name"
            autocomplete="on"
          />
        </ElFormItem>
        <ElFormItem
          label="Email"
          prop="email"
        >
          <ElInput
            v-model="formData.email"
            type="email"
            autocomplete="on"
          />
        </ElFormItem>
        <!-- <ElFormItem
          label="Phone"
          prop="phone"
        >
          <ElInput
            v-model="formData.phone"
            type="tel"
          >
          </ElInput>
        </ElFormItem> -->
        <ElFormItem label="Phone" prop="phone">
          <ElInput
            v-model="formData.phone"
            v-mask="currentMask.mask"
            type="tel"
            autocomplete="on"
            :placeholder="currentMask.placeholder"
          />
        </ElFormItem>
        <ElFormItem label="Code" prop="phoneMask">
          <ElSelect v-model="formData.phoneMask" style="width: 100px">
            <ElOption
              v-for="phoneMask in phoneMasks"
              :key="phoneMask.country"
              :value="phoneMask.country"
              :label="phoneMask.country.toUpperCase() + ' ' + phoneMask.countryCode + ' ...'"
            />
          </ElSelect>
        </ElFormItem>
        <ElFormItem label="Password" prop="password">
          <ElInput v-model="formData.password" :type="passwordVisibility.type">
            <template #append>
              <ElButton @click="setPasswordVisibility">
                <font-awesome-icon :icon="passwordVisibility.eye" />
              </ElButton>
            </template>
          </ElInput>
        </ElFormItem>
        <ElFormItem>
          <ElButton
            type="primary"
            native-type="submit"
          >
            Log in
          </ElButton>
          <!-- <ElButton @click="setStrongPassword" type="primary">Set strong password</ElButton> -->
          <ElButton
            type="primary"
            @click="$refs.formRef.resetFields()"
          >
            <font-awesome-icon :icon="['fas', 'refresh']" />
          </ElButton>
        </ElFormItem>
      </ElForm>
    </ElCard>
  </div>
</template>

<script setup>
import { ElForm, ElFormItem, ElInput, ElCard, ElSelect, ElOption } from 'element-plus';

import { inject, reactive, ref, computed, watch, toRef } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';

import { mask as vMask } from 'vue-the-mask';

import useTrack from '../shared/hooks/trackers.js'
import useHelpers from '../shared/hooks/helpers.js'
import { useOneSignal } from '@onesignal/onesignal-vue3';

const mixpanel = inject('mixpanel');
const oneSignal = useOneSignal();
const { trackLogin } = useTrack(mixpanel);


const store = useStore();
const router = useRouter();

const isPasswordVisible = ref(false);
const formData = reactive({
  password: '',
  email: '',
  name: '',
  phone: '',
  phoneMask: 'none',
});

const formRules = {
  password: [
    {
      required: true,
      message: 'Password is required!',
      trigger: 'blur',
    },
    {
      validator: validatePassword,
    }
  ],
  name: [
    {
      required: true,
      message: 'Name is required!',
      trigger: 'blur',
    },
  ],
  email: [
    {
      required: true,
      message: 'Email is required!',
      trigger: 'blur',
    },
    {
      type: 'email',
      message: 'Please, enter a correct email address!',
      trigger: ['blur', 'change']
    },
  ],
  phone: [
    {
      required: true,
      message: 'Phone is required!',
      trigger: ['blur', 'change']
    },
    {
      validator: validatePhone,
    }
  ],
}

const phoneMasks = [
  {
    country: 'none',
    countryCode: '',
    mask: '#'.repeat(15),
    placeholder: 'Phone number',
    phoneRegex: /^\d+$/,
  },
  {
    country: 'ua',
    countryCode: '+380',
    mask: '+380 (##) ###-##-##',
    placeholder: '+380 (93) 123-45-67',
    phoneRegex: /^\+380 \(\d{2}\) \d{3}-\d{2}-\d{2}$/,
  },
  {
    country: 'us',
    countryCode: '+1',
    mask: '+1-###-###-####',
    placeholder: '+1-234-567-8910',
    phoneRegex: /^\+1-\d{3}-\d{3}-\d{4}$/
  }
]

const currentMask = computed(() => phoneMasks.find(mask => mask.country === formData.phoneMask));

watch(currentMask, () => {
  formData.phone = '';
});

const passwordVisibility = computed(() => {
  let eye = ['fas', 'eye'];
  let type = 'password';

  if (isPasswordVisible.value) {
    eye = ['fas', 'eye-slash'];
    type = 'text';
  }

  return {
    eye,
    type,
  }
});

function setPasswordVisibility() {
  isPasswordVisible.value = !isPasswordVisible.value;
}

function validatePassword(rule, value, callback) {
  // const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,16}$/;
  const passwordRegex = /[A-Za-z\d@$!%*?&]{6,16}$/;
  
  if (!passwordRegex.test(value)) {
    callback('Password need to be 6-16 characters');
  } else {
    callback();
  }
}

function validatePhone(rule, value, callback) {
  if (!currentMask.value.phoneRegex.test(value)) {
    callback('Phone is incorrect!');
  } else {
    callback();
  }
}

function onLogin(formElement) {
  if (!formElement) {
    return;
  }

  formElement.validate((valid) => {
    if (valid) {
      let user = {
        ...formData,
        token: '-someAccessTokenStringWithRandomNumbers458389776438332928374-',
      }

      localStorage.setItem('userDC', JSON.stringify(user));

      mixpanel.identify(formData.email);
      setUserInOnesignal(formData.email); //login in Ondesignal

      let people = { '\$email': formData.email }
      if (formData.name) {
        people['name'] = formData.name;
      }

      const { getCleanNumber } = useHelpers();
      const phone = getCleanNumber(formData.phone);

      if (phone) {
        people['\$phone'] = phone;
      }

      mixpanel.people.set(people);
      trackLogin(formData.email, phone);
      store.dispatch('login');
      router.push({ name: 'Default' });
    } else {
      return false;
    }
  })
}

function setUserInOnesignal(usderId) {
      oneSignal.login(usderId);
    }
</script>

<style lang="scss" scoped>
.container-login {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 10px;

  @include media-breakpoint-up(lg) {
    align-items: start;
    padding: 30px 0;
  }
}

.form-container {
  &__title {
    text-align: center;
  }

  @include media-breakpoint-up(sm) {
    width: 450px;
  }
}
</style>
