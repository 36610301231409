<template>
  <div class="dc-courses row">
    <div
      v-for="course in courses"
      :key="course.id"
      class="dc-courses__card-container col-12 col-sm-6 col-xl-4"
    >
      <div class="card dc-courses__card">
        <router-link :to="{ name: 'Course', params: { courseId: course.id } }">
          <div
            class="card-img-block"
            @mouseleave="stopVideo(course)"
          >
            <img
              v-show="currPlay !== course.id"
              :src="course.src"
              class="card-img-top"
              alt="Course preview image"
              @mouseover="playVideo(course)"
            >
            <video
              :id="'player' + course.id"
              :ref="'preview'"
              class="video-js card__video-preview"
              :src="course.preview"
            />
          </div>
        </router-link>
        <div class="card-body">
          <h5 class="card-title">
            {{ course.title }}
          </h5>
          <p class="card-text">
            {{ course.description }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import videojs from 'video.js';
import 'videojs-contrib-hls';

const baseUrl = window.location.protocol +'//'+window.location.host;
let clearTime = null;
export default {
  name: 'CoursesView',
  data() {
    return {
      players: {},
      currPlay: null,
      options: {
        autoplay: false,
        controls: false,
        fluid: true,
        sources: true
      },
    };
  },
  computed: {
    ...mapGetters('coursesModule', {
      courses: 'getCourses',
    })
  },
  mounted() {
    if (this.$refs.preview) {
      this.$refs.preview.forEach(el => {
        let id = el.getAttribute('id');
        let sourceV = el.getAttribute('src');

        this.players[id] = videojs(el, this.options, () => {
          this.players[id].auto;
        });
        this.players[id].src({
          type: 'application/x-mpegURL',
          src: sourceV,
        })
      });
    }
  },
  methods: {
    playVideo(course) {
      this.currPlay = course.id;
      clearTime = setTimeout(() => {
        this.players['player'+course.id].volume(0);
        this.players['player'+course.id].play();
      }, 100)
    },
    stopVideo(course) {
      clearTimeout(clearTime)
      this.players['player'+course.id].pause();
      this.players['player'+course.id].currentTime(0);
      this.currPlay = null;
    }
  },
}
</script>

<style lang="scss" scoped>
.dc-courses {
  &__card-container {
    align-content: start;
    padding: 12px;
  }

  &__card {
    width: 100%;
    height: 100%;
  }
}

.card {
  .card-img-block {
    position: relative;
    overflow: hidden;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    cursor: pointer;
    width: 100%;
    padding-top: 56%;

    .card-img-top {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 3;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &::after {
      content: '';
      opacity: 0;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      transition: .2s;
      
      background: rgba(0, 0, 0, .4);
    }
    &:hover {
      &::after {
        opacity: 1;
      }
    }
  }

  &__video-preview {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .card-body {
    text-align: left;
  }
}
</style>
