<template>
  <div class="author-info">
    <div class="author-info__block">
      <div class="author-info__photo-container">
        <img
          :src="photo"
          alt="Author photo"
          height="80"
          class="author-info__photo"
        >
      </div>
      <div>
        <p class="author-info__name">
          {{ fullName }}
        </p>
        <p class="author-info__description author-info__description--gray">
          Author of {{ title }}
        </p>
      </div>
    </div>

    <div class="author-info__block">
      <p class="author-info__description author-info__description--bold author-info__description--blue">
        <font-awesome-icon :icon="['fas', 'tv']" />
        <!-- {{ currentCourse.videos.length }} -->
        {{ countLessons }}
        Lessons
      </p>
      <p class="author-info__description author-info__description--bold author-info__description--blue">
        <font-awesome-icon :icon="['fas', 'users']" />
        {{ tweened.number.toFixed(0) }} Enrolled
      </p>
    </div>
    <p class="author-info__description mb-3">
      Lorem ipsum dolor sit amet consectetur adipisicing elit. Optio facere dolores aliquid quis illum nemo, perspiciatis non quidem aliquam odio accusamus eum dolor, eius amet beatae harum sint esse pariatur
    </p>
  </div>
</template>

<script setup>
import { defineProps, reactive, toRef, watch } from 'vue';

import gsap from 'gsap';

const props = defineProps({
  title: {
    type: String,
    required: true,
  },
  photo: {
    type: String,
    required: true,
  },
  firstName: {
    type: String,
    required: true,
  },
  lastName: {
    type: String,
    required: true,
  },
  countLessons: {
    type: Number,
    required: true,
  },
  subscribers: {
    type: Number,
    required: true,
  },
});

const fullName = props.firstName + ' ' + props.lastName;

const subscribersRef = toRef(props, 'subscribers');
const tweened = reactive({
  number: subscribersRef.value,
})

watch(subscribersRef, (val) => {
  tweened.number = 0;
  gsap.to(tweened, { duration: 0.5, number: Number(val) || 0 });
})

</script>

<style lang="scss" scoped>
.author-info {
  &__block {
    display: flex;
    align-items: center;
    gap: 15px;
    margin-bottom: 15px;
  }

//  &__photo-container {
//  }

  &__photo {
    border-radius: 5px;
  }
  
  &__name {
    font-weight: bold;
    margin: 0;
  }

  &__description {
    margin: 0;
    
    &--bold {
      font-weight: bold;
    }

    &--gray {
      color: #666;
    }

    &--blue {
      color: #66b1ff;
    }
  }
}
</style>
