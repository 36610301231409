import { createStore } from 'vuex';
import { coursesModule } from './modules/courses.js';
import { booksModule } from './modules/books.js';
import mixpanel from 'mixpanel-browser';

const store = createStore({
  state() {
    return {
      // loggedIn: !!localStorage.getItem('token'),
      loggedIn: !!localStorage.getItem('userDC'),
    };
  },

  modules: {
    coursesModule,
    booksModule,
  },

  getters: {
    getLoggedIn(state) {
      return state.loggedIn;
    }
  },
  mutations: {
    setLoggedIn(state, payload) {
      state.loggedIn = payload;
    }
  },
  actions: {
    login(context) {
      context.commit('setLoggedIn', true);
    },
    logout(context) {
      // localStorage.removeItem('token');
      let getUser = JSON.parse(localStorage.getItem('userDC'));

      localStorage.removeItem('userDC');
      context.commit('setLoggedIn', false);

      mixpanel.track('Web Logout', {
        user: getUser.email
      });
    },
  },
})

export default store;
