import { createRouter, createWebHistory } from 'vue-router';

import CoursesParent from '@/views/Courses/CoursesParent';
import CoursesView from '@/views/Courses/CoursesView';
import CourseItem from '@/views/Courses/CourseItem.vue';

import LoginView from '@/views/LoginView';

import store from '../store';

const routes = [
  {
    path: '/',
    name: 'Default',
    redirect: '/courses',
  },
  {
    path: '/login',
    name: 'Login',
    component: LoginView,
    meta: {
      title: 'Login'
    }
  },
	{
		path: '/courses',
		component: CoursesParent,
    meta: {
      requiredAuth: true,
    },
    children: [
      {
        path: '',
        name: 'Courses',
        component: CoursesView,
        props: true,
        meta: {
          title: 'Courses'
        }
      },
      {
        path: ':courseId',
        name: 'Course',
        component: CourseItem,
        props: true,
        meta: {
          title: 'Course'
        }
      },
    ],
	},
  {
		path: '/library',
    component: () => import(/* webpackChunkName: "library-page" */ '@/views/Library/LibraryParent.vue'),
    meta: {
      requiredAuth: true,
    },
    children: [
      {
        path: '',
        name: 'Library',
        component: () => import(/* webpackChunkName: "library" */ '@/views/Library/LibraryView.vue'),
        meta: {
          title: 'Library'
        },
      },
      {
        path: ':bookId',
        name: 'Book',
        component: () => import(/* webpackChunkName: "book" */ '@/views/Library/CurrentBook.vue'),
        props: true,
        meta: {
          title: 'Book'
        }
      }
    ]
	},
  {
		path: '/push',
		name: 'Push',
		// alias: '/builder/:id',
		// component: BuilderView,
    component: () => import(/* webpackChunkName: "article" */ "@/views/PushTestView.vue"),
    meta: {
      requiredAuth: true,
      title: 'Push Test'
    },
	},
	{
		path: '/:pathMatch(.*)*',
		name: '404',
		redirect: '/',
    meta: {
      requiredAuth: true,
      title: '404 ;('
    },
	}
];

const router = createRouter({
	history: createWebHistory('/'),
	routes
});

router.beforeEach((to, from, next) => {
  const loggedIn = store.getters.getLoggedIn;

  if (to.meta.requiredAuth && !loggedIn) {
    next({ name: 'Login' });
  } else if (to.name === 'Login' && loggedIn) {
    next({ name: 'Default' });
  } else {
    next();
  };

  document.title = 'Digital Code - '+to.meta?.title ?? 'Digital Code'
})

export default router;
