<template>
  <div class="wrapper">
    <HeaderComp />
    <main class="main">
      <router-view v-slot="slotProps">
        <transition name="route" mode="out-in">
          <component :is="slotProps.Component"></component>
        </transition>
      </router-view>
    </main>
    <FooterComp />
  </div>
</template>

<script>
import HeaderComp from './components/UI/HeaderComp.vue';
import FooterComp from './components/UI/FooterComp.vue';

export default {
  name: 'App',
  components: {
    HeaderComp,
    FooterComp
  },
  mounted() {
    // this.initOneSignal();
    // this.sendNotification();
    console.log('Build mode: '+process.env.NODE_ENV);
    console.log('ONESNIGNAL', this.$OneSignal)
  },
  methods: {
    sendNotification() {
      this.$OneSignal.push(() => {
        this.$OneSignal.showNativePrompt();
        // Other OneSignal functions as needed
      });
    },
    initOneSignal() {
          // console.log('--------', this.$OneSignal)
      // if (this.$OneSignal) {
      //   this.$OneSignal.push((OneSignal) => {
      //     console.log('OneSignal', OneSignal)
      //     OneSignal.login('ddd')
      //   });
      // }
      let user = JSON.parse(localStorage.getItem('userDC'));

      function eventListener(event) {
        console.log(`${event} --- KU=ku!`);
      }
      this.$OneSignal.Notifications.addEventListener("permissionPromptDisplay", eventListener);

      if (this.$OneSignal) {

        // this.$OneSignal.showHttpPrompt().then(() => {
        //   console.log('Subscribed to push notifications');
        // });
      } else {
        console.error('OneSignal is not available');
      }

      // this.$OneSignal.init({
      //   appId: "294dd060-443e-4b0d-ba5a-37b0177a7c12",
      //   allowLocalhostAsSecureOrigin: true,
      // }).then((oneSignal) => {
      //   oneSignal.login('osniha@gmail.com');
      //   // do other stuff
      // });
    }
  }
}
</script>

<style lang="scss">
  #app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    // text-align: center;
    color: #2c3e50;
  }
  .wrapper {
    width: 100%;
    // min-height: 100vh;
    height: 100vh;
    // border: 1px solid red;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: 'center';
  }
  .main {
    margin-top: 70px;
    flex-grow: 1;
  }

  * {
    box-sizing: border-box;
    &::-webkit-scrollbar {
      height: 4px;
      width: 5px;
      // background: gray;
      background: transparent;
      border-radius: 10px;
    }
    &::-webkit-scrollbar-thumb {
      background: rgba(0,0,0, .3);
      border-radius: 10px;
    }
  }

  .route-enter-from,
  .route-leave-to {
    transform: translateX(-100%);
    
    @include media-breakpoint-up(lg) {
      transform: scale(1.05);
      opacity: 0;
    }
  }
  .route-enter-active,
  .route-leave-active {
    transition: all 0.3s;
  }
  .route-enter-to,
  .route-leave-from {
    transform: translateX(0);
    
    @include media-breakpoint-up(lg) {
      transform: scale(1);
      opacity: 1;
    }
  }
</style>
