export const booksModule = {
  namespaced: true,
  state() {
    return {
      books: [
        {
          title: 'Foundation 1',
          author: 'Isaac Asimov',
          url: 'Isaac_Asimov_Foundation_1.epub',
          img: 'Isaac_Asimov_Foundation_1.jpg',
          id: 1
        },
        {
          title: 'Foundation 2',
          author: 'Isaac Asimov',
          url: 'Isaac_Asimov_Foundation_2.epub',
          img: 'Isaac_Asimov_Foundation_1.jpg',
          id: 2
        },
        {
          title: 'Foundation 3',
          author: 'Isaac Asimov',
          url: 'Isaac_Asimov_Foundation_3.epub',
          img: 'Isaac_Asimov_Foundation_1.jpg',
          id: 3
        },
        {
          title: 'Foundation 4',
          author: 'Isaac Asimov',
          url: 'Isaac_Asimov_Foundation_4.epub',
          img: 'Isaac_Asimov_Foundation_1.jpg',
          id: 4
        },
        {
          title: 'Foundation 5',
          author: 'Isaac Asimov',
          url: 'Isaac_Asimov_Foundation_5.epub',
          img: 'Isaac_Asimov_Foundation_1.jpg',
          id: 5
        },
        {
          title: 'Foundation 6',
          author: 'Isaac Asimov',
          url: 'Isaac_Asimov_Foundation_6.epub',
          img: 'Isaac_Asimov_Foundation_1.jpg',
          id: 6
        },
        {
          title: 'Foundation 7',
          author: 'Isaac Asimov',
          url: 'Isaac_Asimov_Foundation_7.epub',
          img: 'Isaac_Asimov_Foundation_1.jpg',
          id: 7
        }
      ],
    }
  },

  getters: {
    getBooks(state) {
      return [...state.books];
    }
  },

  mutations: {},
  actions: {},
}
