// * * * * * HARDCODED * * * * * // needs to make baseUrl as a property of store or separate api file
const baseUrl = window.location.protocol + '//' + window.location.host;

export const coursesModule = {
  namespaced: true,
  state() {
    return {
      courses: [
        {
          id: 1,
          author: {
            subscribed: true,
            firstName: 'Vishen',
            lastName: '',
            photo: 'https://digitalcode-app-poc.crio-server.com/courses-media/course-1/author-photo-course-1.png',
            subscribers: 1312
          },
          preview: 'https://vz-f1326224-597.b-cdn.net/c51eb52a-b1e9-43b5-ae56-29fd228763e1/playlist.m3u8', //from bunny.net test2 libarry
          // preview: 'https://vz-61bee6d3-0cf.b-cdn.net/17a6d6aa-7128-49d2-8ec6-8495d234225c/playlist.m3u8', //from bunny.net test libarry
          // preview: 'https://digitalcode-app-poc.crio-server.com/courses-media/course-1/preview-course-1.mp4',
          src: 'https://digitalcode-app-poc.crio-server.com/courses-media/course-1/preview-poster-course-1.webp',
          title: 'The Silva Ultramind System',
          description: "Harness altered states of mind to awaken your mind's fullest potential and transform your reality with this legendary approach to personal growth: used by over 6 million people worldwide.",
          modules: [
            {
              title: 'Preparation',
              description: 'Preview unlocked lessons',
              isShown: false,
              // finished: true,
              videos: [
                {
                  title: 'Course 1, video 1. Course trailer.',
                  src: 'https://vz-61bee6d3-0cf.b-cdn.net/e758c036-aef9-4eb7-a4cf-a3fdc6ef182b/playlist.m3u8',//bunny
                  // src: 'https://digitalcode-app-poc.crio-server.com/videos/Course_trailer_720p.mp4',
                  videoID: 'e758c036-aef9-4eb7-a4cf-a3fdc6ef182b',
                  thumbnail: baseUrl + '/images/course/Course_trailer.jpg',
                  description: 'First video of first course'
                },
                {
                  title: 'Course 1, video 2. Story of the magic book.',
                  src: 'https://vz-61bee6d3-0cf.b-cdn.net/fc1e47e0-d7b4-4cce-a511-ccdc4d93a514/playlist.m3u8',//bunny
                  // src: 'https://digitalcode-app-poc.crio-server.com/videos/strory_of_the_magic_book_that_makes_dreams_a_reality_720p.mp4',
                  videoID: 'fc1e47e0-d7b4-4cce-a511-ccdc4d93a514',
                  thumbnail: baseUrl + '/images/course/thumbnail_strory_of_the_magic_book_that_makes_dreams_a_reality_720p.jpg',
                  description: 'Second video of first course'
                },
                {
                  title: 'Course 1, video 3. Course lesson 1.',
                  src: 'https://vz-61bee6d3-0cf.b-cdn.net/9899a49c-6a6c-45a5-8d0e-07faeb61fa77/playlist.m3u8', //bunny
                  // src: 'https://digitalcode-app-poc.crio-server.com/videos/Course_lesson-1_720p.mp4',
                  videoID: '9899a49c-6a6c-45a5-8d0e-07faeb61fa77',
                  thumbnail: baseUrl + '/images/course/Course_lesson-1.jpg',
                  description: 'Third video of first course'
                },
                {
                  title: 'Course 1, video 4. Course lesson 2.',
                  src: 'https://vz-61bee6d3-0cf.b-cdn.net/3fe73318-9827-4470-bfe5-b0c36d00b58a/playlist.m3u8', //bunny
                  // src: 'https://digitalcode-app-poc.crio-server.com/videos/Course_lesson-2_720p.mp4',
                  videoID: '3fe73318-9827-4470-bfe5-b0c36d00b58a',
                  thumbnail: baseUrl + '/images/course/Course_lesson-2.jpg',
                  description: 'Fourth video of first course'
                },
              ],
            },
            {
              title: 'Week 1: The Fundamentals',
              description: 'Preview unlocked lessons',
              isShown: false,
              videos: [
                {
                  title: 'Course 1, video 1. Course trailer.',
                  src: 'https://vz-61bee6d3-0cf.b-cdn.net/e758c036-aef9-4eb7-a4cf-a3fdc6ef182b/playlist.m3u8',//bunny
                  videoID: 'e758c036-aef9-4eb7-a4cf-a3fdc6ef182b',
                  thumbnail: baseUrl + '/images/course/Course_trailer.jpg',
                  description: 'First video of first course'
                },
                {
                  title: 'Course 1, video 2. Story of the magic book.',
                  src: 'https://vz-61bee6d3-0cf.b-cdn.net/fc1e47e0-d7b4-4cce-a511-ccdc4d93a514/playlist.m3u8',//bunny
                  videoID: 'fc1e47e0-d7b4-4cce-a511-ccdc4d93a514',
                  thumbnail: baseUrl + '/images/course/thumbnail_strory_of_the_magic_book_that_makes_dreams_a_reality_720p.jpg',
                  description: 'Second video of first course'
                },
                {
                  title: 'Course 1, video 3. Course lesson 1.',
                  src: 'https://vz-61bee6d3-0cf.b-cdn.net/9899a49c-6a6c-45a5-8d0e-07faeb61fa77/playlist.m3u8', //bunny
                  videoID: '9899a49c-6a6c-45a5-8d0e-07faeb61fa77',
                  thumbnail: baseUrl + '/images/course/Course_lesson-1.jpg',
                  description: 'Third video of first course'
                },
                {
                  title: 'Course 1, video 4. Course lesson 2.',
                  src: 'https://vz-61bee6d3-0cf.b-cdn.net/3fe73318-9827-4470-bfe5-b0c36d00b58a/playlist.m3u8', //bunny
                  videoID: '3fe73318-9827-4470-bfe5-b0c36d00b58a',
                  thumbnail: baseUrl + '/images/course/Course_lesson-2.jpg',
                  description: 'Fourth video of first course'
                },
              ],
            }
          ]
        },
        {
          id: 2,
          author: {
            subscribed: false,
            firstName: 'Jim',
            lastName: 'Kwik',
            photo: 'https://digitalcode-app-poc.crio-server.com/courses-media/course-2/author-photo-course-2.jpg',
            subscribers: 667,
          },
          preview: 'https://vz-f1326224-597.b-cdn.net/b0a67462-c92b-469e-9a55-81ce93722ffe/playlist.m3u8', //from bunny.net test2 libarry
          // preview: 'https://vz-61bee6d3-0cf.b-cdn.net/2d0a1fe5-ceab-4901-908a-f715a2b04623/playlist.m3u8', //from bunny.net test libarry
          // preview: 'https://digitalcode-app-poc.crio-server.com/courses-media/course-2/preview-course-2.mp4',
          src: 'https://digitalcode-app-poc.crio-server.com/courses-media/course-2/preview-poster-course-2.webp',
          title: 'Superbrain',
          description: "Awaken your mind's limitless potential, gain a bulletproof memory, and achieve superhuman productivity with this accelerated learning curriculum by master mind trainer Jim Kwik.",
          modules: [
            {
              title: 'Preparation',
              description: 'Preview unlocked lessons',
              isShown: false,
              videos: [
                {
                  title: 'Course 1, video 1. Course trailer.',
                  src: 'https://vz-61bee6d3-0cf.b-cdn.net/e758c036-aef9-4eb7-a4cf-a3fdc6ef182b/playlist.m3u8',//bunny
                  videoID: 'e758c036-aef9-4eb7-a4cf-a3fdc6ef182b',
                  thumbnail: baseUrl + '/images/course/Course_trailer.jpg',
                  description: 'First video of first course'
                },
                {
                  title: 'Course 1, video 2. Story of the magic book.',
                  src: 'https://vz-61bee6d3-0cf.b-cdn.net/fc1e47e0-d7b4-4cce-a511-ccdc4d93a514/playlist.m3u8',//bunny
                  videoID: 'fc1e47e0-d7b4-4cce-a511-ccdc4d93a514',
                  thumbnail: baseUrl + '/images/course/thumbnail_strory_of_the_magic_book_that_makes_dreams_a_reality_720p.jpg',
                  description: 'Second video of first course'
                },
              ],
            },
            {
              title: 'Week 1: The Fundamentals',
              description: 'Preview unlocked lessons',
              isShown: false,
              videos: [
                {
                  title: 'Course 1, video 1. Course trailer.',
                  src: 'https://vz-61bee6d3-0cf.b-cdn.net/e758c036-aef9-4eb7-a4cf-a3fdc6ef182b/playlist.m3u8',//bunny
                  videoID: 'e758c036-aef9-4eb7-a4cf-a3fdc6ef182b',
                  thumbnail: baseUrl + '/images/course/Course_trailer.jpg',
                  description: 'First video of first course'
                },
                {
                  title: 'Course 1, video 2. Story of the magic book.',
                  src: 'https://vz-61bee6d3-0cf.b-cdn.net/fc1e47e0-d7b4-4cce-a511-ccdc4d93a514/playlist.m3u8',//bunny
                  videoID: 'fc1e47e0-d7b4-4cce-a511-ccdc4d93a514',
                  thumbnail: baseUrl + '/images/course/thumbnail_strory_of_the_magic_book_that_makes_dreams_a_reality_720p.jpg',
                  description: 'Second video of first course'
                },
              ],
            }
          ]
        },
        {
          id: 3,
          author: {
            subscribed: false,
            firstName: 'Marisa',
            lastName: 'Peer',
            photo: 'https://digitalcode-app-poc.crio-server.com/courses-media/course-3/author-photo-course-3.jpg',
            subscribers: 2023,
          },
          preview: 'https://vz-f1326224-597.b-cdn.net/2c2a6294-d87b-48da-863e-d898f36f86a8/playlist.m3u8',//from bunny.net test2 libarry
          // preview: 'https://vz-61bee6d3-0cf.b-cdn.net/695bba73-6c92-46f2-9182-21f7b22ede09/playlist.m3u8',//from bunny.net test libarry
          // preview: 'https://digitalcode-app-poc.crio-server.com/courses-media/course-3/preview-course-3.mp4',
          src: 'https://digitalcode-app-poc.crio-server.com/courses-media/course-3/preview-poster-course-3.webp',
          title: 'Rapid Transformational Hypnotherapy for Abundance',
          description: "End self-sabotage and reprogram your subconscious mind for fast and permanent transformation in all areas of your life with the guidance of Marisa Peer: Britain's no. 1",
          modules: [
            {
              title: 'Preparation',
              description: 'Preview unlocked lessons',
              isShown: false,
              videos: [
                {
                  title: 'Course 1, video 1. Course trailer.',
                  src: 'https://vz-61bee6d3-0cf.b-cdn.net/e758c036-aef9-4eb7-a4cf-a3fdc6ef182b/playlist.m3u8',//bunny
                  videoID: 'e758c036-aef9-4eb7-a4cf-a3fdc6ef182b',
                  thumbnail: baseUrl + '/images/course/Course_trailer.jpg',
                  description: 'First video of first course'
                },
                {
                  title: 'Course 1, video 2. Story of the magic book.',
                  src: 'https://vz-61bee6d3-0cf.b-cdn.net/fc1e47e0-d7b4-4cce-a511-ccdc4d93a514/playlist.m3u8',//bunny
                  videoID: 'fc1e47e0-d7b4-4cce-a511-ccdc4d93a514',
                  thumbnail: baseUrl + '/images/course/thumbnail_strory_of_the_magic_book_that_makes_dreams_a_reality_720p.jpg',
                  description: 'Second video of first course'
                },
              ],
            },
            {
              title: 'Week 1: The Fundamentals',
              description: 'Preview unlocked lessons',
              isShown: false,
              videos: [
                {
                  title: 'Course 1, video 1. Course trailer.',
                  src: 'https://vz-61bee6d3-0cf.b-cdn.net/e758c036-aef9-4eb7-a4cf-a3fdc6ef182b/playlist.m3u8',//bunny
                  videoID: 'e758c036-aef9-4eb7-a4cf-a3fdc6ef182b',
                  thumbnail: baseUrl + '/images/course/Course_trailer.jpg',
                  description: 'First video of first course'
                },
                {
                  title: 'Course 1, video 2. Story of the magic book.',
                  src: 'https://vz-61bee6d3-0cf.b-cdn.net/fc1e47e0-d7b4-4cce-a511-ccdc4d93a514/playlist.m3u8',//bunny
                  videoID: 'fc1e47e0-d7b4-4cce-a511-ccdc4d93a514',
                  thumbnail: baseUrl + '/images/course/thumbnail_strory_of_the_magic_book_that_makes_dreams_a_reality_720p.jpg',
                  description: 'Second video of first course'
                },
              ],
            }
          ]
        },
      ]
    }
  },

  getters: {
    getCourses(state) {
      return [...state.courses];
    },
  },

  mutations: {
    subscribe(state, id) {
      const author = state.courses.find(course => course.id === id).author;

      author.subscribers++;
      author.subscribed = true;
    },
    unsubscribe(state, id) {
      const author = state.courses.find(course => course.id === id).author;

      author.subscribers--;
      author.subscribed = false;
    },
  },
  actions: {
    subscribe(context, id) {
      context.commit('subscribe', id);
    },
    unsubscribe(context, id) {
      context.commit('unsubscribe', id);
    },
  },
}
