<template>
  <div>
    <div class="row">
      <div class="col-12 col-md-6 col-lg-5">
        <div class="mb-4">
          <router-link :to="{ name: 'Courses' }">
            <ElButton link>
              <font-awesome-icon :icon="['fas', 'arrow-left']" />&nbsp;
              Back to Course List
            </ElButton>
          </router-link>
        </div>
        <h2 class="mb-3">
          {{ currentCourse.title }}
        </h2>
        <CourseAuthor
          :title="currentCourse.title"
          :photo="currentCourse.author.photo"
          :subscribers="currentCourse.author.subscribers"
          :firstName="currentCourse.author.firstName"
          :lastName="currentCourse.author.lastName"
          :countLessons="countLessons"
        />
        <transition name="subscribe-button" mode="out-in">
          <ElButton
            v-if="!currentCourse.author.subscribed"
            type="primary"
            round
            class="subscribe-button"
            @click="store.dispatch('coursesModule/subscribe', currentCourse.id)"
          >
            Subscribe
          </ElButton>
          <ElButton
            v-else
            type="danger"
            round
            class="subscribe-button"
            @click="store.dispatch('coursesModule/unsubscribe', currentCourse.id)"
          >
            Unsubscribe
          </ElButton>
        </transition>
      </div>
  
      <div class="col-12 col-md-6 col-lg-7">
        <div class="mb-4">
          {{ `${currentCourse.title} preview:` }}
        </div>
        <div class="preview">
          <div class="preview-bl">
            <video id="vPlayerPreview" ref="vPlayerPreview" class="video-js" muted="muted"></video>
          </div>
        </div>
      </div>
    </div>
  
    <ElDialog
      v-model="isDialogVisible"
      :title="currentVideo ? currentVideo.title : ''"
      modalClass="dc-dialog"
      @opened="setPlayer"
      @close="closeDialog"
    >
      <div class="video-mjs">
        <div class="video-mjs__player">
          <div style="position:relative;padding-top:56.25%;" v-if="currentVideo">
            <iframe
              :src="`https://iframe.mediadelivery.net/embed/135568/${currentVideo.videoID}?autoplay=true&loop=false&muted=false&preload=true`"
              loading="lazy"
              style="border:0;position:absolute;top:0;height:100%;width:100%;"
              allow="accelerometer;gyroscope;autoplay;encrypted-media;picture-in-picture;"
              allowfullscreen="true"
            />
          </div>
          <!-- <div class="video-mjs__player-bl">
            <video id="videoDialog" ref="videoPlayer" class="video-js"></video>
          </div> -->
          <div class="player-info mt-2" v-if="currentVideo">
            <div class="player-description">
              {{ currentVideo.description }}
            </div>
          </div>
        </div>
      </div>
    </ElDialog>
  
    <ElTabs v-model="activeTab" class="demo-tabs">
      <ElTabPane label="Overview" name="overview">
        <CourseOverview
          :authorPhoto="currentCourse.author.photo"
          :coursePhoto="currentCourse.src"
        />
      </ElTabPane>
  
      <ElTabPane label="Lessons" name="lessons">
        <CourseLessons
          :modules="modules"
          :currentVideo="currentVideo"
          @showEnteredModule="showModule"
          @openEnteredVideo="onOpenVideo"
        />
      </ElTabPane>
    </ElTabs>
  </div>
</template>

<script setup>
// * * * * * IMPORT COMPONENTS * * * * * //
import { ElTabs, ElTabPane, ElMessageBox } from 'element-plus';
import CourseAuthor from './CourseAuthor.vue';
import CourseOverview from './CourseOverview.vue';
import CourseLessons from './CourseLessons.vue';

// * * * * * IMPORT CORE * * * * * //
import { computed, ref, reactive, defineProps, inject, onMounted, onBeforeUnmount, watch } from 'vue';
import { useStore } from 'vuex';

// * * * * * IMPORT LIBRARIES * * * * * //
// import videojs from 'videojs-contrib-hls.js';
import videojs from 'video.js';
import 'videojs-contrib-hls';

// * * * * * IMPORT HOOKS/HELPERS * * * * * //
import useTrack from '../../shared/hooks/trackers.js';
import useHelpers from '../../shared/hooks/helpers.js';

const { deepCopyWithJSON } = useHelpers();

const store = useStore();
const mixpanel = inject('mixpanel');
const { trackCourse, trackModule, trackVideo } = useTrack(mixpanel);

const props = defineProps({
  courseId: {
    type: String,
    required: true,
  }
});

const activeTab = 'overview';
const courses = computed(() => store.getters['coursesModule/getCourses']);
const currentCourse = ref(courses.value.find(course => course.id === +props.courseId));
const modules = ref([...currentCourse.value.modules]);
const player = ref(null);
const currentVideo = ref(null);
const playerPreview = ref(null);
const countLessons = computed(() => {
  let count = 0;
  modules.value.forEach(module => count += module.videos.length);
  return count;
});
const optionsHLS = reactive({
  autoplay: true,
  controls: true,
  fluid: true,
  sources: true
});
const vPlayerPreview = ref(null);

let previewInterval = null;
let previewVideoState = null;
let currVideoState = null;
let currVideoInterval = null;

// * * * * * LIFECYCLE HOOKS * * * * * //
onMounted(() => {
  if (currentCourse.value) {
      let settedOptionsHLS = {
        ...optionsHLS,
        sources: [
          {
            type: 'application/x-mpegURL',
            src: currentCourse.value.preview,
          }
        ]
      }
      playerPreview.value = videojs(vPlayerPreview.value, settedOptionsHLS, () => {
        playerPreview.value.auto;
      });
      playerPreview.value.volume(0.1);
      
      previewInterval = setInterval(() => {
        console.log('INTERVAL');
        if (!playerPreview.value.paused()) handlerStatePreview();
      }, 1000);
    }
  });

  onBeforeUnmount(() => {
    if (player.value) {
        clearInterval(currVideoInterval);
        player.value.dispose();
      }
      if (playerPreview.value) {
        clearInterval(previewInterval);
        console.log('INTERVAL ENDED');
        playerPreview.value.dispose();
        trackVideo('timeSpend', previewVideoState);
      }
  });

if (currentCourse.value) trackCourse('viewCourse', currentCourse);

function handlerStatePreview() {
  previewVideoState = {};
  previewVideoState['duration'] = Math.round(playerPreview.value.duration());
  previewVideoState['timeSpent'] = Math.round(playerPreview.value.currentTime());
  previewVideoState['titleCourse'] = deepCopyWithJSON(currentCourse.value.title);
  previewVideoState['titleVideo'] = 'Preview';
}

function handlerStateVideo() {
  currVideoState = {};
  currVideoState['duration'] = Math.round(player.value.duration());
  currVideoState['timeSpent'] = Math.round(player.value.currentTime());
  currVideoState['titleCourse'] = currentCourse.value.title;
  currVideoState['titleVideo'] = currentVideo.value.title;
  console.log('Current state video-----')
}

const isDialogVisible = ref(false);

function onOpenVideo(indexM = 0, indexV = 0) {
  isDialogVisible.value = true;
  
  let currentVideoCopy = deepCopyWithJSON(modules.value[indexM].videos[indexV]);
  currentVideo.value = {
    ...currentVideoCopy,
    indexM,
    indexV
  };

  courseState('viewed');

  let isPreviewPaused = playerPreview.value.paused();
  if (!isPreviewPaused) {
    playerPreview.value.pause();
  }
}

function onCloseVideo(close) {
  ElMessageBox.confirm('Video timer will be reset if you close it.')
    .then(() => {
      close();
    })
    .catch(() => {});
}

function playerEvents() {
  player.value.on('ended', () => {
    // videoState('finished');
    courseState('finished');
    clearInterval(currVideoInterval);
  });
  player.value.on('play', () => {
    console.log('Play Video');
    currVideoInterval = setInterval(() => {
      if (!player.value.paused()) handlerStateVideo();
    }, 1000)
  });
  player.value.on('pause', () => {
    console.log('Pause Video');
    clearInterval(currVideoInterval);
  });
  player.value.on('seeked', () => {
    console.log('Seeked');
  });
}

function setPlayer() {
  // if (player.value) {
  //   player.value = videojs(videoPlayer.value, this.optionsHLS, () => {
  //     player.value.auto;
  //   })

  //   player.value.src({
  //     type: 'application/x-mpegURL',
  //     scr: currentVideo.value.src,
  //   });

  //   player.value.volume(0.1);
    // playerEvents();
  // } else {
  //   player.value.src({
  //     type: 'application/x-mpegURL',
  //     scr: currentVideo.value.src,
  //   });
  //   player.value.volume(0.1);
  // }
}

function closeDialog() {
  // trackVideo('timeSpend', deepCopyWithJSON(currVideoState));
  // player.reset();
  currentVideo.value = ref(null);
  clearInterval(currVideoInterval);
  currVideoState = null;

  console.log('Dialog is closed!');
}

function courseState(type) {
  if (type === 'viewed') {
    const { indexM, indexV } = currentVideo.value;
    console.log('Current video: ', currentVideo.value);

    if (!modules.value[indexM].viewed) {
      modules.value[indexM]['viewed'] = true;
      trackModule('viewModule', modules.value[indexM].title, currentCourse.value.title);
    }

    if (!modules.value[indexM].videos[indexV].viewed) {
      modules.value[indexM].videos[indexV]['viewed'] = true;
      trackVideo('viewVideo', modules.value[indexM].title, currentCourse.value.title, currentVideo.value.title);
    }
  }

  if (type === 'finished') {
    if (!currentCourse.value.finished) {
      const { indexM, indexV } = currentVideo.value;

      if (currentVideo.value && !modules.value[indexM].videos[indexV].finished) {
        modules.value[indexM].videos[indexV]['finished'] = true;
        trackVideo('finished', modules.value[indexM].title, currentCourse.value.title, currentVideo.value.title);
      }
      
      let fModules = 0;
      modules.value.forEach((el, iM) => {
        if (el.finished) {
          fModules++
        } else {
          let fVideos = 0;
          el.videos.forEach( (v, iV) => {
            if (v.finished) fVideos++
          });
          if (fVideos === el.videos.length) {
            modules.value[indexM]['finished'] = true;
            fModules++
            trackModule('finished', modules.value[indexM].title, currentCourse.value.title);
          }
        }
      });
      if (fModules === modules.value.length) {
        currentCourse.value['finished'] = true;
        trackCourse('finished', currentCourse);
      }
    }
  }
}

function showModule(index) {
  modules.value[index].isShown = !modules.value[index].isShown;
}
</script>

<style lang="scss">
  .preview {
    // border: 2px solid red;
    &-bl {
      position: relative;
      width: 100%;
      padding-top: 56%;
      margin-bottom: 50px;
      border-radius: 15px;
      overflow: hidden;
    }

    .video-js {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  .el-dialog {
    margin: 0 10px 0;
    .el-dialog__body {
      padding: 10px;
    }
  }

  .el-overlay-dialog {
    padding: 10px;
  }

  .video-mjs {
    display: flex;
    align-items: flex-start;

    &__player {
      // border: 2px solid red;
      width: 100%;
      // max-width: 840px;
      flex-shrink: 0;

      .player-title {
        text-align: left;
        margin-bottom: 10px;
        font-size: 28px;
        line-height: 28px;
      }

      .player-description {
        font-size: 16px;
        text-align: left;
      }

      &-bl {
        position: relative;
        width: 100%;
        padding-top: 56%;
        margin-bottom: 50px;
      }

      .video-js {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
    &__playlist {
      flex-grow: 1;
    }
  }

  .subscribe-button {
    margin-bottom: 30px;

    @include media-breakpoint-up(md) {
      margin-bottom: 0;
    }

    &-enter-from,
    &-leave-to {
      opacity: 0;
      transform: scale(1.2);
    }

    &-enter-active,
    &-leave-active {}

    &-enter-to,
    &-leave-from {
      opacity: 1;
      transform: scale(1);
    }
  }
</style>
