import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faUserSecret,
  faPenToSquare,
  faPenRuler,
  faLeftLong,
  faCheck,
  faTriangleExclamation,
  faFolder,
  faCircleExclamation,
  faArrowUpRightFromSquare,
  faFlag,
  faExplosion,
  faPlay,
  faAnglesLeft,
  faAnglesRight,
  faChevronDown,
  faArrowLeft,
  faTv,
  faUsers,
  faRefresh,
  faEye,
  faEyeSlash,
  faSignOut,
  faBars,
  faSignIn,
} from '@fortawesome/free-solid-svg-icons';

const faIcons = [
  faUserSecret,
  faPenToSquare,
  faPenRuler,
  faLeftLong,
  faCheck,
  faTriangleExclamation,
  faFolder,
  faCircleExclamation,
  faArrowUpRightFromSquare,
  faFlag,
  faExplosion,
  faPlay,
  faAnglesLeft,
  faAnglesRight,
  faChevronDown,
  faArrowLeft,
  faTv,
  faUsers,
  faRefresh,
  faEye,
  faEyeSlash,
  faSignOut,
  faBars,
  faSignIn,
];

faIcons.forEach(el => {
  library.add(el);
});
