<template>
  <div class="courses-parent container">
    <router-view v-slot="slotProps">
      <transition name="route" mode="out-in">
        <component :is="slotProps.Component" />
      </transition>
    </router-view>
  </div>
</template>

<script>
export default {
  name: 'CoursesParent',
}
</script>

<style lang="scss" scoped>
  .courses-parent {
    padding: 15px;

    @include media-breakpoint-up(sm) {
      padding: 30px;
    }

    @include media-breakpoint-up(xl) {
      padding-top: 50px;
      padding-bottom: 50px;
    }
  }
</style>
